<template>
  <div class="row">
    <div class="col-12">
      <Widget class="h-auto">
        <template #title>Calendario</template>

        <button v-if="canPost" class="btn btn-block btn-medium" @click="createItem">
          Nueva Fecha
        </button>
        <vue-table
          v-if="vTable.headers"
          :values="items"
          :headers="vTable.headers"
          :actions="actions"
          :options="vTable.options"
          ref="vtable"
          :empty_message="canGet ? `No hay fechas para mostrar` : `No tienes permisos para ver el calendario`"
          @changed="getItems"
          @onEditItem="onEditItem"
          @onDeleteItem="deleteItem"
        />
        <!-- @onShowItem="onShowItem" -->
      </Widget>
    </div>

    <CalendarioCreateModal
      v-if="showModal"
      @close="closeModal"
      :item="selItem"
      @saved="onSaveItem"
    />
    <CalendarioUpdateModal
      v-if="showModalUpdate"
      @close="closeModal"
      :item="selItem"
      @saved="onSaveItem"
    />
  </div>
</template>

<script>
import Widget from "@/components/Widget.vue";
import CalendarioCreateModal from "./CalendarioCreateModal.vue";
import CalendarioUpdateModal from "./CalendarioUpdateModal.vue";
import {can} from '@/acl/methods'

export default {
  components: {
    Widget,
    CalendarioCreateModal,
    CalendarioUpdateModal,
  },
  data: () => ({
    vTable: {
      headers: [
        {
          title: "fecha",
          mask: "Fecha Evento",
          dateFormat: "DD-MM-YYYY",
          sortable: true,
        },
        {
          title: "content",
          mask: "Lugar",
          sortable: true,
        },
        {
          title: "informacion",
          mask: "Informacion Extra",
          sortable: true,
        },
        {
          title: "jueces",
          mask: "Juez/es",
          sortable: true,
        },
      ],
      actions: [
        {
          buttonClass: "btn-warning",
          callback: "onEditItem",
          tooltip: "Editar Galería",
          icon: "fa-solid fa-pencil",
        },
        // {
        //   buttonClass: "btn-info",
        //   callback: "onShowItem",
        //   tooltip: "Ver Galería",
        //   icon: "fa-solid fa-eye",
        // },
        {
          buttonClass: "btn-danger",
          callback: "onDeleteItem",
          tooltip: "Borrar Noticia",
          icon: "fa-solid fa-trash",
        },
      ],
      options: {
        /// Go to OPTIONS SECTION for explanation
      },
    },
    showModal: false,
    showModalUpdate: false,
    selItem: null,
  }),
  mounted() {
    this.initializeTable();
  },
  computed: {
    items() {
      return this.$store.getters.calendario;
    },
    canGet(){
      return can(this.$store.getters.user, 'calendario', ['get'])
    },
    canPost(){
      return can(this.$store.getters.user, 'calendario', ['post'])
    },
    canPut(){
      return can(this.$store.getters.user, 'calendario', ['put'])
    },
    canDel(){
      return can(this.$store.getters.user, 'calendario', ['delete'])
    },
    actions(){
      const actions = this.vTable.actions
      if (!this.canPut) {
        actions.splice(actions.findIndex(a => a.callback === 'onEditItem'), 1)
      }
      // if(this.canPut){
      //   actions.splice(actions.findIndex(a => a.callback === 'onShowItem'), 1)
      // }
      if (!this.canDel) {
        actions.splice(actions.findIndex(a => a.callback === 'onDeleteItem'), 1)
      }
      return actions
    }
  },
  methods: {
    initializeTable() {
      this.$refs.vtable.init();
    },
    closeModal() {
      this.selItem = null;
      this.showModal = false;
      this.showModalUpdate = false;
    },
    getItems(params) {
      this.$store.dispatch("getCalendario", params);
    },
    onEditItem(item) {
      this.showModalUpdate = true;
      this.selItem = item;
    },
    // onShowItem(item) {
    //   this.showModal = true;
    //   this.selItem = item;
    // },
    createItem() {
      this.showModal = true;
    },
    onSaveItem() {
      this.initializeTable(); // Reset table
    },
    deleteItem(item) {
      this.$alert
        .fireConfirm("Borrar Fecha", "Esta seguro que desea continuar?")
        .then(() => {
          this.$store.dispatch("deleteCalendario", item.id).then(() => {
           this.onSaveItem();
        });
        });
    },
  },
};
</script>
