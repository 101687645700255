<template>
  <vue-modal @close="$emit('close')" ref="modal" size="md">
    <template #title>Crear Evento</template>
    <template #body>
      <form @submit.prevent="createEvento">
          <div class="col-md-12">
            <FormText
            label="Evento"
            v-model="form.content"
            />
            <FormText
              label="Informacion Extra"
              v-model="form.informacion"
            />
            <FormText
              label="Juez/ces"
              v-model="form.jueces"
            />
          </div>
          <div class="col-md-12 row">
            <div class="col-md-6">
              <FormSelect
                label="Prioridad"
                :options="prioridades"
                v-model="form.type"
              />
            </div>
            <div class="col-md-6">  
              <FormDate v-model="form.fecha" label="Fecha"/>
            </div>
          </div>
      </form> 
    </template>
    <template #footer>
      <button class="btn btn-primary" type="submit" @click="createEvento">
        Crear
      </button>
    </template>
  </vue-modal>
</template>
<script>
import FormText from "@/components/Form/FormText.vue";
import FormDate from "@/components/Form/FormDate.vue";
import FormSelect from "@/components/Form/FormSelect.vue";

export default {
  components: {
    FormText,
    FormSelect,
    FormDate,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    prioridades: [{value: 'success', name: "Baja"},{value: 'warning', name: "Media"},{value: 'error', name: "Alta"},],
    form: {
      content: null,
      informacion: null, 
      jueces: null,
      type: 'success',
      fecha: null,
      day: null,
      month: null,
      year: null,
    },
  }),
  mounted() {
    if (this.item) this.form = {...this.item}
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    createEvento(){
      if(!this.form.fecha) return this.$toast.error("Debe selecionar una fecha en el calendario...");
      this.form.day = new Date(this.form.fecha).getDate()
      this.form.month = (new Date(this.form.fecha).getMonth() + 1)
      this.form.year = (new Date(this.form.fecha).getFullYear())
      const form = this.form
      const id = this.item.id
      this.$store.dispatch("updateCalendario", {id, form}).then(()=>{this.close()})
      this.$emit('saved')
    },
  },
};
</script>
<style></style>
